import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { LoaderModule } from '@app/shared/loader/loader.module';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackDialogComponent } from './feedback-dialog.component';

@NgModule({
  declarations: [FeedbackDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, LoaderModule, TranslateModule],
  exports: [FeedbackDialogComponent],
})
export class FeedbackDialogModule {}
