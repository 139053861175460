import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material.module';
import { FeedbackDialogModule } from '../dialogs/feedback-dialog/feedback-dialog.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserMenuRouterLinkDirective } from './user-menu-router-link.directive';
import { UserMenuComponent } from './user-menu.component';

@NgModule({
  declarations: [UserMenuComponent, UserMenuRouterLinkDirective],
  imports: [CommonModule, UserAvatarModule, TranslateModule, MaterialModule, RouterModule, FeedbackDialogModule],
  exports: [UserMenuComponent],
})
export class UserMenuModule {}
